
@import "~normalize.css/normalize";

@import "~flexboxgrid/dist/flexboxgrid.min";

@import "~@nishanths/zoom.js/css/zoom";

@import "_nav";

* {
  box-sizing: border-box;
}

body {
  font-family: 'Source Serif Pro', serif;
  font-size: 12pt;
  margin-top: 86px;
  color: #807c7a;
}

@media only screen and (min-width: 48em) {
  .container {
    width: 48rem;
  }
}
@media only screen and (min-width: 64em) {
  .container {
    width: 64rem;
  }
}
@media only screen and (min-width: 75em) {
  .container {
    width: 67rem;
  }
}

.container {
  padding: 0px 15px 0px 15px;
}

.container-fluid {
  @media only screen and (min-width: 64em) {
    padding: 0;
  }
}

h1, h2, h3, h4 {
  font-family: 'Source Sans Pro', sans-serif;
  text-transform: uppercase;
}

.m-b-60 {
  margin-bottom: 60px;
}

header {
  background-size: cover;
  height: 45vh;
  background-position: center center;
  //margin-bottom: 60px;
  background-color: #b0aaa9;

  &.hero {
    height: 60vh;
    background-image: url(../images/bath.jpg);
    background-position: center 70%;
  }

  &#team {
    background-image: url(../images/eco_2.jpg);
    background-position: center 100%;
  }


  &#wij-doen {
    background-image: url(../images/wij-doen.jpg);
    background-position: center 40%;
  }

  &#contact {
    background-image: url(../images/map.jpg);
    background-position: center center;
    height: 22em;
    .container .accent {
      mix-blend-mode: normal;
      opacity: 0.7;
    }
    @media only screen and (max-width: 47.9em) {
      // enkel mobi
      .accent {
        width: 225px;
      }
    }
  }

  @media only screen and (min-width: 48em) {
    &.header--right {
      .container {
        .accent, .content {
          left: auto;
          right: 15px;
        }

        .title {
          right: 75px;
          left: auto;
        }
      }
    }
  }

  .container {
    position: relative;
    height: 100%;
    overflow: auto;

    .accent {
      background-color: #0e4070;
      mix-blend-mode: overlay;
      position: absolute;
      top: 0;
      left: 15px;
      bottom: 0;
      width: 30%;
      @media only screen and (min-width: 48em) {;
        width: 310px;
      }
    }

    .title {
      position: absolute;
      left: 40px;
      bottom: 100px;
      color: white;
      width: 320px;

      @media only screen and (min-width: 48em) {
        left: 142px;
      }

      h1 {
        font-size: 44px;
        letter-spacing: 13px;
        margin: 0;

        @media only screen and (min-width: 48em) {
          font-size: 69px;
        }
      }

      h2 {
        font-size: 30px;
        letter-spacing: 8px;
        margin: 0;

        @media only screen and (min-width: 48em) {
          font-size: 40px;
        }
      }

      span {
        font-family: 'Source Sans Pro', sans-serif;
        text-transform: uppercase;
        font-weight: 400;
        letter-spacing: 2px;
        font-size: 13px;
        line-height: 1.7em;
        margin: 0;
        //letter-spacing: normal;

        p {
          margin: 0;padding-left:5px;
        }

        @media only screen and (min-width: 48em) {
          font-size: 15px;
        }
      }
    }

    .content {
      width: 295px;
      color: #fff;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 310px;
      padding: 50px;

      h2 {
        position: relative;
        left: -25px;
        font-weight: bold;
        letter-spacing: 5px;
        border-left: 2px solid #fff;
        padding: 0 20px;
        margin: 10px 0;
      }
      p {
        line-height: 1.9em;
        font-weight: bold;
        font-size: 1.1em;
        letter-spacing: 3px;
        margin-bottom: 30px;
      }
      a {
        color: white;
        text-decoration: underline;

        &:hover,
        &:active {
          text-decoration: none;
        }
      }
    }
  }
}

.closing-line{
  padding-bottom: 15px;

  &::after {
    display: block;
    content: '';
    width: 60px;
    height: 2px;
    background: #e62a23;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

.page-section {
  margin-bottom: 60px;

  p {
    line-height: 1.6em;
    position: relative;
  }

  .content {
    // padding: 15px 0;

    ul {
      columns: 2;
      padding-left: 0;
      list-style-position: inside;
      position: relative;

      @media only screen and (max-width: 47.9em) {
        columns: 1;
      }

      li {
        padding-bottom: 0.5em;
      }
    }
  }

  h2 {
    font-weight: bold;
    letter-spacing: 5px;
    color: #000;

    &.accent {
      border-left: 2px solid #e62a23;
      padding: 10px 20px;
    }
  }

  h3 {
    font-weight: 300;
    font-size: 20px;
    line-height: 1.5em;
    color: #232323;
    margin: 12px 0;
    //hyphens: auto;

    span {
      color: #e62a23;
    }
  }

  h4 {
    font-weight: bold;
    font-size: 19px;
    color: #232323;
    margin: 12px 0;
  }
}

.team-member {
  text-align: center;
  margin: 40px 0;

  h3 {
    font-size: 24px;
    font-weight: 400;
    margin: 8px 0;
  }
  h4 {
    margin: 8px 0;
  }
  p {
    letter-spacing: 0.14em;
    font-family: 'Source Sans Pro', sans-serif;
    margin: 8px;
  }
}

.m-b-0 {
  margin-bottom: 0;
}

.photos {
  width: 100%;

  @media only screen and (max-width: 64em) {
    .row{
      margin-right: -1.5rem;
      margin-left: -1.5rem;
    }
  }

  .photo {
    padding: 0;
    // height: 25vh;

    display: flex;
    justify-content: center;
    align-items: center;

    img {
      height: 256px;
      // height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}

footer {
  background-color: #232323;

  .row {
    min-height: 40vh;
  }

  h2 {
    color: #ccc;
    position: relative;
    left: -25px;
    font-weight: bold;
    letter-spacing: 5px;
    border-left: 2px solid #2a4d6f;
    padding: 10px 20px;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-bottom: 30px;
    li {
      padding: 6px 0;

      a {
        color: #807c7a;
        text-transform: uppercase;
        font-family: 'Source Sans Pro', sans-serif;

        &:hover, &:active {
          color: #ccc;
        }
      }
    }
  }
}
