
// LINKS RESET
a {
  text-decoration: none;
  color: rgba(34, 34, 34, .8);
  &:hover,
  :focus {
    color: darken(#000, 20%);
  }
}

$text-color: #000;

.container--nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 1.5rem 0;
  background: rgba(255, 255, 255, 1);
  border-bottom: 1px solid lightgrey;
  z-index: 3;
  will-change: transform;
  transition: background .3s, -webkit-transform .5s cubic-bezier(.694, .048, .335, 1);
  transition: transform .5s cubic-bezier(.694, .048, .335, 1), background .3s;
  transition: transform .5s cubic-bezier(.694, .048, .335, 1), background .3s, -webkit-transform .5s cubic-bezier(.694, .048, .335, 1);
  transform: translateY(0);
  -webkit-transform: translateY(0);

  nav {
    .logo {
      float: left;
      padding-top: .25rem;
      padding-bottom: .25rem;
      margin-right: 1rem;
      height: 32px;
      @media only screen and (min-width: 48em) {
        height: 40px;
      }
      &:after {
        content: '';
        display: table;
        clear: both;
      }
      img {
        height: 100%;
      }
    }
    ul {
      list-style: none;
      padding-left: 0;
      margin: 0;
      clear: both;

      @media only screen and (min-width: 48em) {
        clear: none;
      }

      li {
        float: none;
        margin-left: 0;
        padding: .7em 0;

        @media only screen and (min-width: 48em) {
          float: left;
          padding: 0;
          margin-left: 2rem;
        }
        a {
          font-family: 'Source Sans Pro', sans-serif;
          text-transform: uppercase;

          display: inline-block;
          position: relative;
          padding-bottom: 0.3rem;
          transition: color .35s ease;
          &:before {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            bottom: 0;
            height: 2px;
            width: 0;
            transition: width 0s ease, background .35s ease;
          }
          &:after {
            content: '';
            display: block;
            position: absolute;
            right: 0;
            bottom: 0;
            height: 2px;
            width: 0;
            background: $text-color;
            transition: width .35s ease;
          }
          &:hover:before {
            width: 100%;
            background: $text-color;
            transition: width .35s ease;
          }
          &:hover:after {
            width: 100%;
            background: transparent;
            transition: all 0s ease;
          }

          @media only screen and (min-width: 48em) {
            display: block;
            padding: .425rem 0rem;
          }
        }
      }
    }
  }
}

.hide-nav {
  transform: translateY(-120%!important);
  -webkit-transform: translateY(-120%)!important;
}

ul.menu-left {
  display: block;
  max-height: 0;
  overflow: hidden;
  transition: all 0.3s ease-out;
  z-index: 10;
  @media only screen and (min-width: 48em) {
    display: block!important;
    float: right;
    max-height: none;
  }
  &:before {
    content: '';
    display: table;
    clear: both;
  }
  &.collapse {
    max-height: 15em!important;
  }
}

// Burger
.nav-toggle {
  display: block;
  border-radius: 5px;
  background-color: transparent;
  float: right;
  height: 38px;
  width: 38px;
  cursor: pointer;
  padding: 8px 8px;
  &.open span:first-child {
    transform: rotate(45deg) translate(4.4px, 4.4px);
  }
  &.open span:nth-child(2) {
    width: 0%;
    opacity: 0;
  }
  &.open span:last-child {
    transform: rotate(-45deg) translate(4.4px, -4.4px);
  }
  @media only screen and (min-width: 48em) {
    display: none;
  }
  span {
    position: relative;
    display: block;
    height: 2px;
    width: 100%;
    margin-top: 4px;
    background-color: $text-color;
    transition: all .25s;
  }
}